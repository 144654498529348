<template>
    <div>
        <el-tabs>
            <el-tab-pane label="Certificate">
                <el-button type="primary" @click="exportPDF('showTotal')" size="medium">Download the
                    certificate</el-button>

                <div id="showTotal">
                    <div v-for="(item, index) in awards" :key="index" class="certContainer16"
                        style="margin-bottom:10px;">
                        <img src="../../../assets/image/wao_cert.jpg" class="modelCert" />

                        <div class="cert">{{ "Certificate of Excellence" }}</div>

                        <div class="student">{{ upfirstName(item.student.student_givenName_pinyin) + ' ' +
                            upfirstName(item.student.student_lastName_pinyin) }}</div>

                        <div class="school">School: {{ item.account.account_nameEN }}</div>

                        <div class="award">{{ item.award_lable }}</div>

                        <div class="event">{{ projectInfo.project_product }}</div>

                        <div class="date1" v-html=formatCertDate(projectManage.entry_date[0].cert_date)>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Transcript">
                <el-button type="primary" @click="exportPDF('showTranscript')" size="medium">Download the
                    transcript</el-button>
                <div id="showTranscript">
                    <div v-for="(item, index) in transcripts" :key="index" class="transcriptContainer2"
                        style="margin-bottom:10px;">
                        <img src="../../../assets/image/wao_transcript.png" class="model_p" />
                        <div class="name">{{ upfirstName(item.student_info.student_givenName_pinyin) + ' ' +
                            upfirstName(item.student_info.student_lastName_pinyin) }}</div>

                        <div class="email">{{ item.student_info.student_email }}</div>

                        <div class="account">{{ item.account_info.account_nameEN }}</div>

                        <div class="track">{{ projectInfo.project_product_line }}</div>

                        <div class="event">{{ projectInfo.project_product }}</div>

                        <div class="score">{{ item.score_info[0].total_score + ' / ' + item.score_info[0].full_score}}</div>

                        <div class="grade">{{ formatGradeInfo(item.score_info[0].level_and_grade.grade) }}</div>

                        <div class="diffculty_level">Level {{ item.score_info[0].level_and_grade.diffculty_level }}
                        </div>

                        <div class="date">{{ formatCertDateV1(projectManage.entry_date[0].cert_date) }}</div>

                    </div>
                </div>
            </el-tab-pane>


            <el-tab-pane label="Grade">
                <el-button type="primary" @click="exportPDF('showGrade')" size="medium">Download the
                    grade</el-button>

                <div id="showGrade">
                    <div v-for="(item, index) in transcripts" :key="index" class="certContainer16"
                        style="margin-bottom:10px;">
                        <img src="../../../assets/image/wao_cert.jpg" class="modelCert" />

                        <div class="cert">{{ "Certificate of Excellence" }}</div>

                        <div class="student">{{ upfirstName(item.student_info.student_givenName_pinyin) + ' ' +
                            upfirstName(item.student_info.student_lastName_pinyin) }}</div>

                        <div class="school">School: {{ item.account_info.account_nameEN }}</div>

                        <div class="award">{{ formatGradeInfo(transcripts[0].score_info[0].level_and_grade.grade) }}</div>

                        <div class="event">{{ projectInfo.project_product }}</div>

                        <div class="date1" v-html=formatCertDate(projectManage.entry_date[0].cert_date)>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getStudentAwardsV1, getStudentTranscripts, getProjectByCode } from '../../../api/wao'
import {
    getUserId
} from '../../../utils/store'
export default {
    name: 'index',
    data() {
        return {
            awards: [],
            user_id: getUserId(),
            transcripts: [],
            projectInfo: {},
            projectManage: {},
        }
    },
    mounted() {
        this.project_code = this.$route.query.project_code
        this.fetchData()
    },
    methods: {


        formatGradeInfo(grade) {
            if (grade >= 4) {
                return 'Excellence'
            }
            if (grade >= 3) {
                return 'Distinction'
            }
            if (grade >= 2) {
                return 'Great Distinction'
            }
            return 'Highest Distinction'
        },

        fetchData() {

            getProjectByCode(this.project_code).then((res) => {
                this.projectInfo = res.data.data
                this.projectManage = this.projectInfo.projectManage
            })

            getStudentAwardsV1(this.project_code, getUserId()).then(res => {
                if (res.data.code === 0) {
                    this.awards = res.data.data
                }
            })

            getStudentTranscripts(this.project_code, getUserId()).then((res) => {
                this.transcripts = res.data.data
            })

        },
        rank(number) {
            var tail = number.toString().charAt(number.toString().length - 1, 1)
            if (tail === '1' && number !== 11) {
                return "st";
            } else if (tail === '2' && number !== 12) {
                return "nd";
            } else if (tail === '3' && number !== 13) {
                return "rd";
            } else {
                return "th";
            }
        },
        convertToChinaNum(num) {

            var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');

            var arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿');//可继续追加更高位转换值    

            if (!num || isNaN(num)) {

                return "零";

            }

            var english = num.toString().split("")

            var result = "";

            for (var i = 0; i < english.length; i++) {

                var des_i = english.length - 1 - i;//倒序排列设值        

                result = arr2[i] + result;

                var arr1_index = english[des_i];

                result = arr1[arr1_index] + result;

            }

            //将【零千、零百】换成【零】 【十零】换成【十】    

            result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');

            //合并中间多个零为一个零    

            result = result.replace(/零+/g, '零');

            //将【零亿】换成【亿】【零万】换成【万】    

            result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');

            //将【亿万】换成【亿】    

            result = result.replace(/亿万/g, '亿');

            //移除末尾的零    

            result = result.replace(/零+$/, '')

            //将【零一十】换成【零十】    

            //result = result.replace(/零一十/g, '零十');

            //貌似正规读法是零一十    

            //将【一十】换成【十】    

            result = result.replace(/^一十/g, '十');

            return result;

        },

        showEvent(event) {

            if (event == 'Artithon') {
                return 'Artithon - Academic Marathon in Art'
            }

            if (event == 'Biothon') {
                return 'Biothon - Academic Marathon in Biology'
            }

            if (event == 'Chemithon') {
                return 'Chemithon - Academic Marathon in Chemistry'
            }
            if (event == 'Econthon') {
                return 'Econthon - Academic Marathon in Economics'
            }
            if (event == 'Geothon') {
                return 'Geothon - Academic Marathon in Geography'
            }
            if (event == 'Historithon') {
                return 'Historithon - Academic Marathon in History'
            }
            if (event == 'Mathethon') {
                return 'Mathethon - Academic Marathon in Mathematics'
            }

            if (event == 'Physithon') {
                return 'Physithon - Academic Marathon in Physics'
            }

            if (event == 'Psychothon') {
                return 'Psychothon - Academic Marathon in Psychology'
            }


        },

        upfirstName(name) {

            if (name == 'João Pedro') {

                return name
            }
            if (name == 'AGYEN-FREMPONG') {
                return name
            }

            return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function (word) {
                //return word.slice(0, 1).toUpperCase() + word.slice(1);
                return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
            });

            if (name) {
                //         const transferName = name.toLowerCase()
                //   return transferName.charAt(0).toUpperCase() + transferName.slice(1)     



                if (name.indexOf(" ") != -1) {
                    var newarr, newarr1;
                    newarr = name.toLowerCase().split(" ");
                    for (var i = 0; i < newarr.length; i++) {
                        newarr[i] = newarr[i][0].toUpperCase() + newarr[i].substring(1, newarr[i].length);
                    }
                    newarr1 = newarr.join(" ");
                    return newarr1;
                } else {
                    const transferName = name.toLowerCase();
                    return transferName.charAt(0).toUpperCase() + transferName.slice(1)
                }
            } else {
                return name
            }

        },
        exportPDF(id) {


            var style = document.createElement('style');

            style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            document.getElementsByTagName("body")[0].style.zoom = 1

            // if (id == 'showTotal' || id =='') {
            //     // style.innerHTML = "@page{size:landscape 1528px 1080px;}";
            //     style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            //     document.getElementsByTagName("body")[0].style.zoom = 1
            // } else {
            //     // style.innerHTML = "@page{size:portrait 2480px 3508px;margin: auto 0mm}";
            //     style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            //     document.getElementsByTagName("body")[0].style.zoom = 1
            // }

            window.document.head.appendChild(style)

            const printData = document.getElementById(id).innerHTML


            this.PageSetup_Null();//把页眉页脚设置为空


            window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
            window.print() // 开始打印


            //   const printData = document.getElementById(id).innerHTML

            //   window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
            //   window.print() // 开始打印
        },
        PageSetup_Null() {
            var HKEY_Root, HKEY_Path, HKEY_Key;

            HKEY_Root = "HKEY_CURRENT_USER";

            HKEY_Path = "\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

            try {
                var Wsh = new ActiveXObject("WScript.Shell");

                HKEY_Key = "header";

                Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

                HKEY_Key = "footer";

                Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

            } catch (e) { }

        },
        formatCertDate(publish_time) {
            let publishTime = publish_time;
            var date = new Date(publishTime);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + "." + day + "<br>" + year;
        },
        formatCertDateV1(publish_time) {
            let publishTime = publish_time;
            var date = new Date(publishTime);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEngV1(month) + " " + day + ", " + year;
        },
        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "Jan";
                case 2:
                    return "Feb";
                case 3:
                    return "Mar";
                case 4:
                    return "Apr";
                case 5:
                    return "May";
                case 6:
                    return "Jun";
                case 7:
                    return "Jul";
                case 8:
                    return "Aug";
                case 9:
                    return "Sep";
                case 10:
                    return "Oct";
                case 11:
                    return "Nov";
                case 12:
                    return "Dec";
            }
        },
        formatMonthToEngV1(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },
    }
}
</script>

<style lang="scss">
.certContainer16 {
    position: relative;

    .modelCert {
        width: 210mm;
        height: 295mm;
        z-index: 0;
    }

    .event {
        color: #0c3481;
        width: 210mm;
        position: absolute;
        top: 605px;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
    }

    .date1 {
        padding-right: 100px;
        bottom: 220px;
        width: 210mm;
        text-align: right;
        position: absolute;
        font-size: 22px;
        font-weight: 500;
    }

    .award {
        color: #0c3481;
        width: 210mm;
        position: absolute;
        top: 510px;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
    }

    .school {
        width: 210mm;
        position: absolute;
        top: 430px;
        text-align: center;
        font-size: 18px;
        font-weight: 350;
    }

    .cert {
        color: #0c3481;
        width: 210mm;
        position: absolute;
        top: 250px;
        font-size: 45px;
        font-weight: 600;
        text-align: center;
    }

    .student {
        color: #0c3481;
        position: absolute;
        top: 370px;
        width: 210mm;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }

}

.transcriptContainer2 {
    position: relative;

    .model_p {
        width: 210mm;
        height: 295mm;
        z-index: 0;
    }

    .diffculty_level {
        position: absolute;
        top: 640px;
        left: 270px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }


    .grade {
        position: absolute;
        top: 615px;
        left: 170px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    .date {
        position: absolute;
        top: 850px;
        left: 100px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }

    .score {
        position: absolute;
        top: 587px;
        left: 173px;
        font-size: 14px;
        font-weight: 500;
        color: #0c3481;
        text-align: center;
    }

    .track {
        position: absolute;
        top: 420px;
        left: 170px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }

    .event {
        position: absolute;
        top: 450px;
        left: 170px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }

    .email {
        position: absolute;
        top: 345px;
        left: 250px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }

    .name {
        position: absolute;
        top: 315px;
        left: 280px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }

    .account {
        position: absolute;
        top: 375px;
        font-size: 16px;
        left: 220px;
        font-weight: 400;
    }

}
</style>
